




















import { Component, Vue } from 'vue-property-decorator';
import { UpdateUserAccountType } from "@/graphql/auth";
import { CreateWorkspaceMutation, WorkspaceQuery } from "@/graphql/workspace";
import { CreateOnboardingNorthstars } from "@/graphql/NorthStar";
import { CreateBoardMutation } from "@/graphql/board";
import InRoomActivation from "@/components/auth/register/InRoomActivation.vue";
import CuratedGoalsSelector from "@/components/templates/CuratedGoalsSelector.vue";
import UserType from "@/components/auth/register/UserType.vue";
import NorthStarService from "@/NorthStarService";
import { NorthStar, Objective as ObjectiveObject, Objective } from "@/typescript/types";
import gql from "graphql-tag";
import { ObjectiveDetailedFragment } from "@/graphql/objective";
import { startOfMonth, endOfMonth, addMonths } from 'date-fns';

@Component({
  components: {
    InRoomActivation,
    CuratedGoalsSelector,
    UserType,
  },
  

})


export default class RegistrationSteps extends Vue {
  currentStep: Number = 2;
  userAccountType: string | null = null;
  isLoading: boolean = false;
  nameWorkspace: boolean = false;
  workspaceName: string | null = 'Work';
  permissions = 'workspace';
  autoCreating: boolean = false;
  referralCode: string | null = null;
  templateId: string | null = null;
  service: NorthStarService | null = null;
  lastAddedNorthstar: NorthStar | null = null;
  addedNorthStars: NorthStar[] = [];
  addedObjectives: Objective[] = [];
  assignedPriority: string = 'normal';
  preOnboardingGoals: Record<string, { name: string; activities: string[] }> = this.$store.state.pre_onboarding_goals;

  // Remove hardcoded activities
  // activities = [ ... ];

  createExampleData: boolean = true; // <--- Set to false to send user into empty room

  // Get the current date
  currentDate = new Date();

  // Set start date to the 1st day of the current month
  firstStartDate: Date = startOfMonth(this.currentDate);

  // Set due date to the last day of the current month
  firstDueDate: Date = endOfMonth(this.currentDate);

  // Set start date to the 1st day of the next month
  secondStartDate: Date = startOfMonth(addMonths(this.currentDate, 1));

  // Set due date to the last day of the next month
  secondDueDate: Date = endOfMonth(addMonths(this.currentDate, 1));

  

  mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    this.referralCode =
      this.$store.state.autoGenUserData && this.$store.state.autoGenUserData!.referral_code
        ? this.$store.state.autoGenUserData!.referral_code
        : urlParams.get('u_referral_code');
    this.templateId =
      this.$store.state.autoGenUserData && this.$store.state.autoGenUserData!.template_id
        ? this.$store.state.autoGenUserData!.template_id
        : urlParams.get('template_id');
    this.$gtag.event('pmb_app_onboarding_mounted');

    this.service = new NorthStarService(this.$apollo);

    if (this.$store.state.currently_onboarding_in_room || this.workspace) {
      this.$router.go(1);
    } else {
      this.autoCreate();
    }

    if (this.$store.state.onboarding_use_case) {
      this.$gtag.event('pmb_onboarding_use_case_' + this.$store.state.onboarding_use_case);
    }
  }

  autoCreate() {
    this.autoCreating = true;
    this.createWorkspace();
  }

  get onboardingUseCase() {
    return this.$store.state.onboarding_use_case;
  }

  get workspaceDemoName() {
    if (this.onboardingUseCase === 'collaborative') {
      return 'Work';
    } else if (this.onboardingUseCase === 'personal') {
      return 'Home';
    } else {
      return 'Work';
    }
  }

  get boardName() {
    return 'Now'; // Simplified as it was the same for all cases
  }

  get domainName() {
    return this.workspaceDemoName;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get boardPid() {
    return this.$store.state.board.pid;
  }

  get preOnboardingGoal() {
    return this.$store.state.pre_onboarding_goals;
  }

  updateUserAccountType(type: string) {
    if (this.isLoading) {
      return;
    }
    this.userAccountType = type;
    this.isLoading = true;
    this.$gtag.event('pmb_app_onboarding_user_type_' + type);
    this.$apollo
      .mutate({
        mutation: UpdateUserAccountType,
        variables: {
          account_type: this.userAccountType,
        },
      })
      .then((_data: any) => {
        this.$gtag.event('pmb_app_onboarding_account_type_selected');
        this.createWorkspace();
      })
      .catch((e: any) => {
        Vue.prototype.$consoleLog(e);
      });
  }

  createWorkspace() {
    this.$apollo
      .mutate({
        mutation: CreateWorkspaceMutation,
        variables: {
          name: this.workspaceDemoName,
          domain: this.domainName,
          referral_code: this.referralCode,
          template_id: this.templateId,
        },
      })
      .then(({ data: { createWorkspace: workspace } }) => {
        this.$gtag.event('pmb_app_onboarding_workspace_created');
        this.$store.commit('set_workspace', workspace);
        this.$store.commit('set_active_workspace_pid', workspace.pid);
        this.$store.commit('set_active_workspace_access', workspace.my_access);
        this.$store.commit('set_currently_onboarding', true);
        this.$store.commit('set_claim_account_prompt', 1);
        this.$store.commit('set_show_first_offer', true);
        this.createBoard(workspace.id);
      })
      .catch((error) => {
        throw error;
      });
  }

  createBoard(workspaceId: string) {
    this.$apollo
      .mutate({
        mutation: CreateBoardMutation,
        variables: {
          name: this.boardName,
          workspace: workspaceId,
          permissions: this.permissions,
          board_level_identifier: 2,
          sprint_interval: 2,
        },
        refetchQueries: [{ query: WorkspaceQuery, variables: { pid: this.$store.state.active_workspace_pid } }],
      })
      .then(({ data: { createBoard: board } }) => {
        this.$gtag.event('pmb_app_onboarding_board_create_success');
        this.$store.commit('set_board', board);
        this.$store.commit('set_active_board_pid', board.pid);
        this.$store.commit('set_last_active_board_pid', board.pid);
      })
      .catch((_error) => {
        this.$gtag.event('pmb_app_onboarding_board_create_failed');
      })
      .finally(() => {
        if (this.createExampleData) {
          this.createGoalsFromStore();
        } else {
          this.completeRegistration();
        }
      });
  }

  async createGoalsFromStore() {
    try {
      let goals = [];
      let activities = [];
      for (const goalId in this.preOnboardingGoals) {
        if (Object.prototype.hasOwnProperty.call(this.preOnboardingGoals, goalId)) {
          //@ts-ignore
          goals.push([this.preOnboardingGoals[goalId].name, this.preOnboardingGoals[goalId].color]);
          if('activities' in this.preOnboardingGoals[goalId]){
            activities.push([...this.preOnboardingGoals[goalId].activities]);

          }
          
        }
      }
      //console.log(goals, activities)
      //@ts-ignore
      this.createOnboardingNorthstars(goals, activities);
      
    } catch (error) {
      console.error('Error creating goals:', error);
    
    }
  }

  async createOrAddNorthStar(
    goalName: string,
    goalColor: string,
    startDate: Date | null,
    dueDate: Date | null,
    //@ts-ignore
    goalId: string,
    activities: string[]
  ) {
    this.$gtag.event('pmb_new_app_activation_northstar_added');
  
    if (this.$store.state.board) {
      try {
        const result = await this.service?.createNorthStar(
          this.$store.state.workspace,
          this.$store.state.board,
          goalName,
          dueDate,
          startDate,
          goalColor
        );

        if (result && result.data && result.data.createNorthStar) {
          this.$events.fire('new-northstar-id-created', result.data.createNorthStar.id);
          this.$store.commit('set_newly_created_northstar_count', this.$store.state.newlyCreatedNorthstarsCountInWorkspace ? this.$store.state.newlyCreatedNorthstarsCountInWorkspace + 1 : 1);
          this.$events.fire('new_northstar_created', result.data.createNorthStar);
          this.lastAddedNorthstar = result.data.createNorthStar;
          this.addedNorthStars.push(result.data.createNorthStar);
        }

        this.$store.commit('set_newly_created_northstar_count', this.$store.state.newlyCreatedNorthstarsCountInWorkspace ? this.$store.state.newlyCreatedNorthstarsCountInWorkspace + 1 : 1);
        this.$gtag.event('pembio_activation_create_north_star');
        

        // Await each activity creation
        for (const activity of activities) {
          await this.createObjectiveMutation(activity, null, null);
        }

        this.$emit('northstar-added', true);
      } catch (error) {
        this.$gtag.event('pmb_app_onboarding_northstar_creation_failed');
        console.error('Error creating NorthStar or objectives:', error);
      }
    }
  }

  async createObjectiveMutation(activity: string, startDate: Date | null, dueDate: Date | null) {
    this.$gtag.event('pmb_new_app_activation_objective_added');

    return this.$apollo
      .mutate({
        mutation: gql`
          mutation createObjective(
            $owner_id: ID!
            $name: String!
            $board_id: ID!
            $start_date: DateTime
            $due_date: DateTime
            $priority: ObjectivePriority
            $background_color: String
          ) {
            createObjective(
              input: {
                owner: { connect: $owner_id }
                board: { connect: $board_id }
                name: $name
                start_date: $start_date
                due_date: $due_date
                priority: $priority
                background_color: $background_color
              }
            ) {
              ...ObjectiveDetailed
            }
          }
          ${ObjectiveDetailedFragment}
        `,
        variables: {
          owner_id: this.$store.state.me.id,
          name: activity,
          board_id: this.$store.state.board.id,
          start_date: startDate,
          due_date: dueDate,
          priority: this.assignedPriority,
        },
      })
      .then((result) => {
        if (this.lastAddedNorthstar) {
          this.attachNorthStar(result.data.createObjective, this.lastAddedNorthstar);
          result.data.createObjective['from_northstar'] = true;
        } else {
          this.$events.fire('objective-created', result.data.createObjective);
        }

        this.addedObjectives.push(result.data.createObjective);
        this.$emit('submitted', true);
        this.$emit('objective-created-from-sprint', result.data.createObjective);
      });
  }

  attachNorthStar(objective: ObjectiveObject, northStar: NorthStar) {
    this.service?.addNorthStarToObjective(objective, [northStar]).then((res) => {
      this.$events.fire('objective-created-from-northstar', { objective: res.data.updateObjective, northStarId: northStar.id });
      return;
    });
  }

  completeRegistration() {
    this.$store.commit('set_currently_onboarding', false);
    this.$store.commit('set_onboarding_completed', true);
    this.$store.commit('set_currently_onboarding_in_room', false);
    this.$store.commit('set_onboarding_activities_added', false);
    this.$store.commit('set_currently_in_activation_step', false);
    this.$store.commit('clear_pre_onboarding_goals');
    this.$store.commit('clear_pre_onboarding_template_goals');
    this.$store.commit('set_template_onboarding', false);
    this.$store.commit('set_expanded_onboarding', 1);
    this.$store.commit('set_show_checklist_prompt', true);
    this.$store.commit('set_display_onboarding_guide', true);
 
    this.$router.push({
      name: 'room',
      params: {
        board_pid: this.boardPid,
        workspace_pid: this.$store.state.active_workspace_pid,
      },
    });
  }

  openCuratedGoalsSelector() {
    this.$buefy.modal.open({
      component: CuratedGoalsSelector,
      width: '700px',
      parent: this,
      canCancel: false,
      customClass: 'curated_modal',
    });
  }

  openActivationModal() {
    this.$buefy.modal.open({
      component: InRoomActivation,
      customClass: 'add_goal_from_onboarding',
      canCancel: false,
      parent: this,
    });
  }

  openUserTypeModal() {
    this.$buefy.modal.open({
      component: UserType,
      customClass: 'user_type_selection',
      canCancel: true,
      parent: this,
    });
  }

  closeModal() {
    // @ts-ignore
    this.$parent.close();
  }

  createOnboardingNorthstars(northstars: Array<[string, string]>, objectives: Array<string[]>) {
    this.$apollo.mutate({
      mutation: CreateOnboardingNorthstars,
      variables: {
        workspace_id: this.$store.state.workspace.id,
        board_id:  this.$store.state.board.id,
        northStars: northstars,
        objectives: objectives,
      },
    }).then((_res) => {
      if(_res.data.createOnboardingNorthstars.length > 0) {
        this.$store.commit('set_last_added_northstar', _res.data.createOnboardingNorthstars[_res.data.createOnboardingNorthstars.length - 1].id);
      }

      this.completeRegistration();
    })
  }
}
